<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6" />
        <b-col
          v-for="(permission, key) in permissions"
          :key="key"
          md="3"
          cols="12"
        >
          <validation-provider
            name="Name"
          >
            <b-form-group
              label="Permission"
              label-for="name"
            >
              <template #label>
                <b-form-checkbox
                  v-model="allSelected[key]"
                  :indeterminate="indeterminate"
                  :aria-describedby="permission.items"
                  :aria-controls="permission.items"
                  @change="toggleAll(permission, key)"
                >
                  <span class="text-capitalize">{{ permission.groupname }}</span>
                </b-form-checkbox>
              </template>
              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="userForm.permission"
                  :options="permission.items"
                  name="name"
                  stacked
                  text-field="name"
                  :aria-describedby="ariaDescribedby"
                  value-field="id"
                  class="ml-4"
                  aria-label="Individual flavours"
                  @change="sekectedChange($event)"
                />
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addRole()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  data() {
    return {
      id: this.$store.state.generalIds.id,
      permissions: [],
      selected: [],
      allSelected: [],
      indeterminate: false,
      allPermissions: [],
      users: [],
      loader: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const userForm = ref({
      name: '',
      permission: [],
    })
    return {
      getValidationState,
      userForm,
    }
  },
  mounted() {
    this.viewPermissions()
    this.showUser()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    toggleAll(value, key) {
      if (this.allSelected[key]) {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.userForm.permission.push(item.id)
        })
      } else {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.userForm.permission.splice(this.userForm.permission.indexOf(item.id), 1)
        })
      }
    },
    showUser() {
      if (this.$route.params.id) {
        axios.get(`roles/${this.$route.params.id}`).then(res => {
          this.userForm.name = res.data.data.name
          this.userForm.permission = res.data.data.permssionIds
        })
      } else {
        return false
      }
      return true
    },
    addRole() {
      if (this.$route.params.id) {
        this.loader = true
        axios.put(`roles/${this.$route.params.id}`, this.userForm).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'roles' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        axios.post('roles', this.userForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'roles' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewPermissions() {
      axios.get('permissions?unlimited=true').then(res => {
        const permissions = res.data.data
        this.permissions = res.data.data
        const foo = _(permissions).groupBy('group').map((item, x) => ({
          items: item,
          groupname: x,
        })).value()
        this.permissions = foo
        this.flavours = permissions.filter(item => item.group === 'merchants')
        this.users = permissions.filter(item => item.group === 'users')
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
